<template>
  <div class="today__container-background">
    <div class="ms-grid today__container">
      <div class="today__block" v-if="this.getAcf">

        <template
          v-for="(r, index) in countAssignedTodayBlocks"
        >
          <c-link class="today__item-container"
          v-if="posts[index].ID"
          :key="index"
          v-bind="processUrl(posts[index].acf.link)">
            <div class="today__item">
              <div class="today__item-image"
              v-lazy:background-image="posts[index].acf.image.url">
                <div class="today__item-category"
                v-html="posts[index].acf.category"></div>
              </div>
              <h2 v-html="posts[index].acf.header"></h2>
            </div>
          </c-link>
        </template>

        <c-link class="today__item-container today__read-more"
        role="button"
        v-bind="processUrl(getAcf.uic_home.red_button_url)">
          <CircleReadMoreSVG class="today-red-button"/>
        </c-link>

      </div>
    </div>
  </div>
</template>

<script>

// SVG
import CircleReadMoreSVG from '@/components/ui-components/svg/circle-read-more-uic-today.svg';

// Mixins
import processUrl from '@/mixins/processUrl';

export default {

  name: 'TodayBlock',
  components: {
    CircleReadMoreSVG,
  },
  mixins: [processUrl],
  data() {
    return {
      posts: [{}, {}, {}, {}],
    };
  },
  computed: {
    getPost() {
      return this.$store.state.postToday;
    },
    getAcf() {
      return this.$store.state.acf;
    },
    assignedTodayBlocks() {
      return this.getAcf.uic_home.today_blocks;
    },
    countAssignedTodayBlocks() {
      let number = 0;
      Object.keys(this.assignedTodayBlocks).forEach((i) => {
        if (this.assignedTodayBlocks[i].today_block.ID
        && this.assignedTodayBlocks[i].today_block.ID != null) {
          number += 1;
          this.$store.dispatch('getPost', { id: this.assignedTodayBlocks[i].today_block.ID, type: 'postToday' });
        }
      });
      // create array from number
      const array = Array.from(Array(number).keys());
      return array;
    },
  },
  watch: {
    getPost(newVal) {
      if (newVal !== null) {
        Object.keys(this.assignedTodayBlocks).forEach((i) => {
          if (this.assignedTodayBlocks[i].today_block.ID === newVal.ID) {
            // this.posts[i] = newVal; // this is for when updating to Vue3; V3 doesnt use $set()
            this.$set(this.posts, i, newVal);
            this.$store.commit('resetPost', { type: 'postToday' });
          }
        });
      }
    },
  },
};

</script>

<style scoped lang="scss">

.today {
  &__container {
    padding-top: 90px;
    padding-bottom: 80px;
    margin-top: 0px;
    margin: 0 auto;
    // max-width: $max-width;
  }
  &__container-background {
    background: $gray;
    width: 100%;
    // padding: 0 20px;
  }
  &__block {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 20px;
    column-gap: 16px;
    justify-content: space-evenly;
  }
  &__item {
    $root-item: &;
    &-container {
      flex-basis: 340px;
      flex-grow: 1;
      text-decoration: none;
      color: $navy;
      &:first-child {
        flex-basis: 700px;
      }
      &:nth-child(2),
      &:nth-child(5) {
        flex-grow: 0;
        @media (max-width: $vp3) {
          flex-grow: 1;
          width: 100%
        }
      }
    }
    background: #ffffff;
    color: $charcoal;
    padding: 0px;
    height: 330px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 17px 0 rgba(0,0,0,.15);
    @media (max-width: $vp4) {
    }
    @media (max-width: $vp3) {
      height: 340px;
    }
    @media (max-width: $vp2) {
      height: 380px;
    }
    &:hover {
      background: $navy;
      color: #ffffff;
      & h2, & #{$root-item}-category {
        color: #ffffff;
        background: $navy;
      }
    }
    &-image { // all images for this component
      overflow: hidden;
      background: transparent no-repeat center center;
      background-size: cover;
      height: 244px;
      @media (max-width: $vp3) {
        min-height: 230px;
      }
      @media (max-width: $vp2) {
        min-height: 300px;
      }
    }
    &-category {
      padding: 10px 15px 8px 15px;
      background: #ffffff;
      width: max-content;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      font-size: 14px;
      @media (max-width: $vp3) {
        font-size: 12px;
      }
    }
    h2 {
      padding: 5px 20px;
      margin: auto 0;
      font-size: 20px;
      color: $navy;
      text-transform: capitalize;
      @media (max-width: $vp3) {
        font-size: 16px;
      }
      @media (max-width: $vp2) {
        font-size: 14px;
      }
    }
  }
  &__read-more {
    text-decoration: none;
    fill:$red;
    &:hover {
      fill:$navy;
    }
  }
}

</style>
